import React from "react";
import "../App.css";
import Card from "./Card";

function Content() {
  return (
    <>
      <div className="mt-32 text-center">
        <h2 className="text-3xl font-bold font-Blacker">SAMPLE CATALOG</h2>
        <p className="mx-auto mt-5 text-lg text-center px-72">
          Dig into our API reference documentation and quickstarts. You’re
          covered with everything from SDKs in your favorite languages to sample
          apps for web, iOS, and Android.
        </p>
      </div>
      <div className="page-content">
        <div className="flex flex-wrap justify-between px-5 border-2 content">
          <a
            href="https://www.apimatic.io/api-docs/inbankapi"
            className="relative flex flex-col justify-start px-6 pt-10 my-6 overflow-hidden border-2 border-gray-200 shadow-md pb-11 lg:card-width hover:shadow-xl"
          >
            <p className="absolute top-0 px-3 py-1 text-white card-tag text-md right-4 rounded-b-md">
              1.0
            </p>
            <h3 className="mt-5 text-3xl text-left font-Blacker">Inbank</h3>
            <p className="mt-5 text-base italic font-semibold">
              (1 API included)
            </p>
            <p>
              The API provides bank partners sell more by simplifying purchases
              and making financing more accessible to customers. For exactly
              this reason we offer a number of buy-now-pay-later (BNPL)
              solutions.
            </p>
          </a>
          <Card
            link="/open-banking-account-information.jsx"
            version="3.1.60"
            title="Open Banking Account Information"
            numberOfAPIs="1"
            description="This API, provides the ability for approved/authorised account information service providers ..."
          />
          <Card
            link="/confirmation-of-funds.jsx"
            version="3.1.5"
            title="Confirmation of Funds"
            numberOfAPIs="1"
            description="The API endpoints described here allow a Card Based Payment Instrument Issuer ('CBPII') to: ..."
          />
          <Card
            link="/open-banking-event-notification.jsx"
            version="3.1.43"
            title="Open Banking Event Notification"
            numberOfAPIs="1"
            description="This API, provides the ability for approved/authorised account information service providers ..."
          />
          <Card
            link="/open-banking-payment-initiation-3.1.19.jsx"
            version="3.1.19"
            title="Open Banking Payment Initiation"
            numberOfAPIs="1"
            description="This API provides the ability for authorised payment initiation service providers (PISPs, TPPs –..."
          />
          <Card
            link="/open-data.jsx"
            version="2.2.4"
            title="Open Data"
            numberOfAPIs="3"
            description="Our APIs are free to use and give you information on the Bank's UK branches, ATMs and products so..."
          />
        </div>
      </div>
    </>
  );
}

export default Content;
