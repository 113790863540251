import React from "react";
import { Link } from "react-router-dom";

function Card(props) {
  return (
    <Link
      to={props.link}
      className="relative flex flex-col justify-start px-6 pt-10 my-6 overflow-hidden border-2 border-gray-200 shadow-md pb-11 lg:card-width hover:shadow-xl"
    >
      <p className="absolute top-0 px-3 py-1 text-white card-tag text-md right-4 rounded-b-md">
        {props.version}
      </p>
      <h3 className="mt-5 text-3xl text-left font-Blacker">{props.title}</h3>
      <p className="mt-5 text-base italic font-semibold">
        ({props.numberOfAPIs} API included)
      </p>
      <p>{props.description}</p>
    </Link>
  );
}

export default Card;
