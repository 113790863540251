// import logo from './logo.svg';
import "./App.css";
import react from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Content from "./Components/Content";

import OpenBankingPaymentInitiation from "./pages/open-banking-payment-initiation";
import OpenBankingPaymentInitiation3119 from "./pages/open-banking-payment-initiation-3.1.19";
import ConfirmationOfFunds from "./pages/confirmation-of-funds";
import OpenBankingAccountInformation from "./pages/open-banking-account-information";
import OpenBankingEventNotification from "./pages/open-banking-event-notification";
import OpenData from "./pages/open-data";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  return (
    <react.Fragment>
      <Header />
      <Router>
        <Switch>
          <Route path="/open-banking-payment-initiation-3.1.19.jsx">
            <OpenBankingPaymentInitiation3119 />
          </Route>
          <Route path="/open-banking-payment-initiation.jsx" >
            <OpenBankingPaymentInitiation />
          </Route>
          <Route path="/confirmation-of-funds.jsx">
            <ConfirmationOfFunds />
          </Route>
          <Route path="/open-banking-account-information.jsx">
            <OpenBankingAccountInformation />
          </Route>
          <Route path="/open-data.jsx">
            <OpenData />
          </Route>
          <Route path="/open-banking-event-notification.jsx">
            <OpenBankingEventNotification />
          </Route>
          <Route path="/">
            <Content />
          </Route>
        </Switch>
      </Router>
      <Footer />
    </react.Fragment>
  );
}

export default App;
