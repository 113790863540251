import React from "react";
import "../App.css";
import Logo from "../images/inbank-logo__purple.svg";

function Header() {
  //   const [isExpanded, toggleExpansion] = useState(false);

  return (
    <nav className="absolute flex items-center justify-between w-full navbar">
      <div className="flex">
        <a href="/">
          <img src={Logo} alt="logo" className="z-50 w-20 lg:h-14 md:h-auto" />
        </a>

        <p className="mt-3 text-xl uppercase xl:text-2xl">
          <span className="w-0.5 h-full bg-white mx-4"></span>Developers
        </p>
      </div>
      <div className="justify-between hidden w-1/2 md:flex xl:w-1/3">
        <p className="text-sm lg:text-base 2xl:text-xl">Home</p>
        <p className="text-sm lg:text-base 2xl:text-xl">Our APIs</p>
        <p className="text-sm lg:text-base 2xl:text-xl">Support</p>
        <p className="text-sm lg:text-base 2xl:text-xl">Switch To Sandbox</p>
      </div>
    </nav>
  );
}

export default Header;
