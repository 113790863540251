import React from "react";
import "../App.css";
import Logo from "../images/inbank-logo__white.svg";

function Footer() {
  //   const [isExpanded, toggleExpansion] = useState(false);

  return (
    <div className="footer">
      <div className="flex items-center justify-between w-full text-white">
        <div className="flex">
          <img
            src={Logo}
            alt="logo"
            className="z-50 w-20 text-white lg:h-14 md:h-auto"
          />

          <p className="pt-3 text-lg uppercase">
            <span className="w-0.5 h-full bg-white mx-4 mt-3"></span>Developers
          </p>
        </div>
        <div className="flex justify-between w-1/3">
          <p className="text-base">Home</p>
          <p className="text-base">Our APIs</p>
          <p className="text-base">Support</p>
          <p className="text-base">Switch To Sandbox</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
